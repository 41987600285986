import externalLinks from './externalLinks';

const links = [
  {
    'headline': 'Brokerage Transfers (ACATS) ',
    'links': [
      {
        'title': 'Transfer an Account from Another Broker (ACATS)',
        'href': externalLinks.withdrawalsAndTransfersSite.transferAccountFromAnotherBroker
      },
      {
        'title': 'ACATS Form Instructions',
        'href': externalLinks.withdrawalsAndTransfersSite.ACATSFormInstructions
      },
      {
        'title': 'Electronically Transfer Your Brokerage Account (Plaid ACAT)',
        'href': externalLinks.withdrawalsAndTransfersSite.electronicallyTransferFromBrokerageAccount
      },
      {
        'title': 'Remaining Cash Balance Still in Old Brokerage Account',
        'href': externalLinks.withdrawalsAndTransfersSite.remainingCashBalanceStillInOldBrokerageAccount
      },
      {
        'title': 'Cost Basis not Updating After an ACAT Transfer',
        'href': externalLinks.withdrawalsAndTransfersSite.costBasisNotUpdatingAfterAnACATTransfer
      },
      {
        'title': 'Transfer My tastytrade Account to Another Brokerage',
        'href': externalLinks.withdrawalsAndTransfersSite.transferMyTastyworksAccountToAnotherBrokerage
      },
      {
        'title': 'Depository Trust Company Number (DTC)',
        'href': externalLinks.withdrawalsAndTransfersSite.depositoryTrustCompanyNumber
      },
      {
        'title': 'DRS Transfers',
        'href': externalLinks.withdrawalsAndTransfersSite.DRSTransfers
      }
    ]
  },
  {
    'headline': 'Internal Transfers',
    'links': [
      {
        'title': 'Transfer Cash or Positions Between tastytrade Accounts',
        'href': externalLinks.withdrawalsAndTransfersSite.transferCashOrPositionsBetweenTastyworksAccounts
      }
    ]
  },
  {
    'headline': 'Wire Transfers',
    'links': [
      {
        'title': 'Wire Withdrawal Instructions',
        'href': externalLinks.withdrawalsAndTransfersSite.wireWithdrawalInstructions
      },
      {
        'title': 'Amount Deducted from Wire Deposit (Intermediary Bank Fees)',
        'href': externalLinks.withdrawalsAndTransfersSite.amountDeductedFromWireDeposit
      }
    ]
  },
  {
    'headline': 'How-To’s & etc.',
    'links': [
      {
        'title': 'How To Fund',
        'href': externalLinks.withdrawalsAndTransfersSite.howToFund
      },
      {
        'title': 'View Pending Deposits or Withdrawals',
        'href': externalLinks.withdrawalsAndTransfersSite.viewPendingDepositsOrWithdrawals
      },
    ]
  }
]

export default links;
